<template>
  <div>
    <v-dialog
      v-model="show"
      :max-width="size ? size : '500px'"
      persistent
      @click:outside="closeModal"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          :class="colorToolbar"
          elevation="0"
        >
          <v-toolbar-title>
            <v-icon
              large
              color="white"
              class="text-36 mr-4"
            >
              {{ icon ? icon : 'mdi-magic-staff' }}
            </v-icon>
            {{ formTitle }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click.native="$emit('close')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="pt-5">
          <slot name="form" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <slot name="actions">
            <div class="mr-5">
              <v-btn
                v-if="!hideDefaultBtn"
                color="primary lighten-2"
                outlined
                class="mr-3"
                @click="$emit('close')"
                @keyup="closeModal"
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="!hideDefaultBtn"
                color="primary"
                depressed
                @click="$emit('save')"
              >
                {{ textBtn2 ? textBtn2 : 'Save' }}
              </v-btn>
            </div>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: ['show', 'formTitle', 'colorToolbar', 'icon', 'textBtn2', 'size', 'hideDefaultBtn'],
    mounted () {
      document.addEventListener('keyup', (e) => {
        if (e.key === 'Escape') {
          this.closeModal()
        }
      })
    },
    methods: {
      closeModal () {
        this.$emit('close')
      },
    },
  }
</script>
